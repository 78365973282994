/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(window).on('load resize', function () {
                    height_equalizer();
                    if ($('#wallonia-map').length) {
                        wallonia_map();
                    }
                });
                // Popover
                $('[data-toggle="popover"]').popover();
                // Fancybox
                $('.fancybox').fancybox({
                    padding: 0
                });
                // Auto Hight Textarea
                autosize($('textarea.autosize'));
                // Facebook share
                $(document).on('click', '.action-fb-share', function (e) {
                    e.preventDefault();
                    FB.ui({
                        method: 'feed',
                        link: window.location.href
                    }, function (response) {
//                        console.log(response);
                    });
                });
                // Single Depute : Load more news
                $('body').on('click', '.action-get-depute-news', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var page = parseInt($this.data('page')) + 1;
                    $.ajax({
                        url: rootsAjax.ajaxUrl,
                        type: 'POST',
                        data: {
                            action: 'more_depute_news',
                            nonce: rootsAjax.nonce,
                            page: page,
                        },
                        success: function (data) {
                            if (data.success) {
                                $this.parent().before(data.html);
                                $this.data('page', page);
                                if (page >= parseInt($this.data('max-num-pages'))) {
                                    $this.remove();
                                }
                            }
                        }
                    });
                });
                // Semaine parlementaire navigation
                $('.action-next-week, .action-previous-week').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var current_page = parseInt($('#current_page').val());
                    var fetch_page = ($this.hasClass('action-previous-week')) ? (current_page - 1) : (current_page + 1);
                    $.ajax({
                        url: rootsAjax.ajaxUrl,
                        type: 'POST',
                        data: {
                            action: 'get_semaine_parlementaire',
                            nonce: rootsAjax.nonce,
                            page: fetch_page
                        },
                        success: function (data) {
                            if (data.success) {
                                $('#current_page').val(fetch_page);
                                $('#semaine-parlementaire-table table > tbody').html(data.html);
                                if (fetch_page > 1) {
                                    $('.action-previous-week').show();
                                } else {
                                    $('.action-previous-week').hide();
                                }
                            }
                        }
                    });
                });
                // Add blue border and remove with condition when focus and blur
                if ($('.fg-line')[0]) {
                    $('body').on('focus', '.form-control', function () {
                        $(this).closest('.fg-line').addClass('fg-toggled');
                    });
                    $('body').on('blur', '.form-control', function () {
                        var p = $(this).closest('.form-group');
                        var i = p.find('.form-control').val();

                        if (p.hasClass('fg-float')) {
                            if (i.length === 0) {
                                $(this).closest('.fg-line').removeClass('fg-toggled');
                            }
                        }
                        else {
                            $(this).closest('.fg-line').removeClass('fg-toggled');
                        }
                    });
                }
                // Add border for pre-valued fg-flot text feilds
                if ($('.fg-float')[0]) {
                    $('.fg-float .form-control').each(function () {
                        var i = $(this).val();
                        if (i.length > 0) {
                            $(this).closest('.fg-line').addClass('fg-toggled');
                        }

                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    // height_equalizer function
    function height_equalizer() {
        $('[data-equalizer]').each(function () {
            var max_height = 0;
            var blocks = ($(this).data('equalizer')) ? $('[data-equalizer-watch="' + $(this).data('equalizer') + '"]') : $('[data-equalizer-watch]', $(this));
            blocks.css('height', 'auto');
            blocks.each(function () {
                if (!$(this).data('height')) {
                    $(this).data('height', $(this).height());
                }
                if ($(this).height() > max_height) {
                    max_height = $(this).height();
                }
            });
            blocks.height(max_height);
        });
    }

    function wallonia_map() {
        var $wallonia_map_sizes = $.parseJSON(rootsAjax.wallonia_map_sizes);
        var $bottom_right_coords = $wallonia_map_sizes.bottom_right_coords;
        var $top_left_coords = $wallonia_map_sizes.top_left_coords;
        var $top_multiplier = $('#wallonia-map').height() / ($bottom_right_coords.lat - $top_left_coords.lat);
        var $left_multiplier = $('#wallonia-map').width() / ($bottom_right_coords.lng - $top_left_coords.lng);
        $('#wallonia-map-container .map-pin').each(function () {
            var $top = (($(this).data('lat') - $top_left_coords.lat) * $top_multiplier);
            var $left = (($(this).data('lng') - $top_left_coords.lng) * $left_multiplier);
            $(this).css({
                top: $top,
                left: $left
            });
        });
        wallonia_map_markers();
    }

    // Homepage map markers & hover effect
    function wallonia_map_markers() {
        var $img_multiplier = 6;
        $('.map-pin img').each(function () {
            if (!$(this).data('width') || !$(this).data('height')) {
                $(this).data('width', $(this).width());
                $(this).data('height', $(this).height());
                $(this).data('left_offset', ((($(this).width() * $img_multiplier) / 2) - ($(this).width() / 2)) * -1);
                $(this).data('top_offset', (($(this).height() * $img_multiplier) - 20) * -1);
                var $depute_name = $(this).parent().find('.depute-name');
                $depute_name.css({
                    'margin-top': ((($(this).height() * $img_multiplier) + $depute_name.height()) / 2) * -1
                });
            }
        });

        $('.map-pin').on('mouseenter', function () {
            $(this).find('img').css({
                width: $img_multiplier * 100 + '%',
                'margin-left': $(this).find('img').data('left_offset') + 'px',
                'margin-top': $(this).find('img').data('top_offset') + 'px'
            });
        }).on('mouseleave', function () {
            $(this).find('img').css({
                width: '100%',
                'margin-left': 0,
                'margin-top': 0
            });
        });
    }

    function distance(lat_a, lon_a, lat_b, lon_b) {
        a = Math.PI / 180;
        lat1 = parseFloat(lat_a) * a;
        lat2 = parseFloat(lat_b) * a;
        lon1 = parseFloat(lon_a) * a;
        lon2 = parseFloat(lon_b) * a;
        t1 = Math.sin(lat1) * Math.sin(lat2);
        t2 = Math.cos(lat1) * Math.cos(lat2);
        t3 = Math.cos(lon1 - lon2);
        t4 = t2 * t3;
        t5 = t1 + t4;
        rad_dist = Math.atan(-t5 / Math.sqrt(-t5 * t5 + 1)) + 2 * Math.atan(1);
        return (rad_dist * 3437.74677 * 1.1508) * 1.6093470878864446;
    }

})(jQuery); // Fully reference jQuery after this point.
